<template>
  <section class="m-wallet-cash m-scroll">

    <!-- 头部导航 -->
    <m-header title="Withdraw"></m-header>


    
    <div class="cash-number">
      <p>Withdrawal Amount</p>
      <div class="input-wrap">
        <span>$</span>
        <input v-model="money" type="text" placeholder="Minimum $2.00">
        <span>All</span>
      </div>
      <div class="tips">
        <span>Withdrawable: ${{$route.query.m}}</span>
        <span>Bank transfer fee: $2.00</span>
      </div>
    </div>


    <!-- 银行卡列表 -->
    <ul class="list">
        <transition-group name="van-slide-down">
            <li class="item" v-for="(it) in list" :key="it.id">
                <van-radio :name="it.id" v-model="cardId" >
                    <p class="card-title">Bank Card</p>
                    <div  class="card-number">
                      <span>{{it.transit_number}}</span>
                      <span>{{it.institiution_number}}</span>
                      <span>{{it.account_number}}</span>
                    </div>
                </van-radio>
            </li>
        </transition-group>
      </ul>

    <router-link to="/zh/wallet/bankcard" class="bank-card" v-if="list.length == 0">
      <div>
        <img src="@/assets/wallet/icon_yinhangka@2x.png" alt="bankcard">
        <span>Add Card</span>
      </div>
      <img src="@/assets/public/icon-fanhui@2x.png" alt="">
    </router-link>

  
    <div class="footer">
      <van-button @click="onNext" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Confirm withdrawal</van-button>
      <p class="times">*Can only withdraw once a month</p>
    </div>







    

  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'

import { card_list, withdrawal } from '@/api/zh/mine.js'

export default {
  name:'WalletWithdrawal',
  components:{ MHeader },
  data(){
    return {
      money:'',
      list:[],
      cardId: '',
    }
  },

  

  methods:{

    // 获取银行卡
    getCard(){
      this.$store.state.load = true
      card_list().then(res => {
        if(res) {
          this.list = res.data
          this.cardId = this.list[0].id
        }
      }).finally( () => {
        this.$store.state.load = false
      })
    },


    onNext(){
      if(this.money == "") {
        this.$notify({ type: 'warning',message: 'Please input the withdrawal amount first', })
        return false
      }
      let params = {
        amount: this.money,
        card_id: this.cardId
      }
      withdrawal(params).then(res => {
        if(res) {
          this.$notify({ type: 'success',message: 'Withdrawal success', });
          this.$router.go(-1)
        }
      })
      
    },

    
   
  },

  created() {
    this.getCard()
  }
}
</script>

<style lang="less" scoped>
.m-wallet-cash {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  .bank-card {
    margin: 10px 16px 16px;
    background-color: #fff;
    height:72px;
    box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
    border-radius:5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 24px;
    &>div {
      display: flex;
      align-items: center;
      >img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
      >span {
        font-size:14px;
        font-weight:400;
        color:#000;
      }
    }
    &>img {
      width: 20px;
      height: 20px;
    }
  }
  .cash-number {
    margin:  0 16px;
    background:rgba(255,255,255,1);
    box-shadow:0px 10px 15px 0px rgba(217,217,217,0.15);
    border-radius:5px;
    padding: 0 12px;
    &>p {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      line-height: 42px;
      border-bottom: 1px solid #E6E6E6;
      margin-bottom: 30px;
      padding-top: 8px;
    }
    .input-wrap {
      height: 48px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #D8D8D8;
      padding: 0 4px;
      &>span:nth-of-type(1) {
        font-size:24px;
        font-weight:500;
        color:rgba(0,0,0,1);
        line-height:33px;
      }
      &>input {
        border: none;
        outline: none;
        height: 100%;
        padding:0 12px 0 6px;
        flex: 1;
      }
      &>input::placeholder {
        font-size: 14x;
        font-weight: 400;
        color: #999;
        
      }
      &>span:nth-of-type(2) {
        font-size:14px;
        font-weight:500;
        color:#3284FA;
        line-height:20px;
      }
    }
    .tips {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 45px;
      font-size: 12px;
      font-weight: 400;
      color: #999;
    }
  }

  .list {
    width: 100%;
    padding: 10px 16px ;;
    /deep/ .van-radio__label {
      margin-left: 15px;
    }
    .item {
      margin-bottom: 12px;
      background-color: #fff;
      border-radius: 5px;
      padding: 8px 20px 8px 12px;
      box-sizing: border-box;
      box-shadow: 0px 4px 20px 0px rgba(8, 84, 191, 0.2);
      .card-title {
        font-size:14px;
        font-weight:bold;
        line-height:25px;
      }
      .card-number {
        >span {
          font-size:14px;
          line-height:20px;
          margin-right: 10px;
        }
      }
    }
  }

  .footer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 46px;
    .times {
      font-size: 12px;
      font-weight: 400;
      color: #999;
      margin-top: 8px;
    }
  }
}
</style>